.wordPad {
    margin-right: 0.15em;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    cursor: pointer;
  
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 133%;
    /* or 27px */
  }
  
.punctuationPad {
    margin-right: 0.3em;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    cursor: pointer;
  
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 133%;
    /* or 27px */
  }