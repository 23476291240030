.edit_podcast_nav{
    display: flex;
    justify-content: space-between;
  }
  .edit_podcast_back{
    cursor: pointer;
  }
  .message_edit_color{
    border-radius: 14px;
    background: #FFF;
    padding: 7px 14px;
    color: #000;
    font-family: Inter;
    font-size: 12.188px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }