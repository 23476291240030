input.slider[type='range'] {
  appearance: none;
  -webkit-appearance: none;
  /*margin: 15px;*/
  width: 100%;
}
input.slider[type='range']:focus {
  outline: none;
}
input.slider[type='range']::-webkit-slider-runnable-track {
  width: 100%;
  height: 3px;
  cursor: pointer;
}
input.slider[type='range']::-webkit-slider-thumb {
  height: 10px;
  width: 10px;
  border-radius: 7px;
  background: black;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -3px;
  border: 0px solid transparent;
}
input.slider[type='range']::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  border-radius: 25px;
}
input.slider[type='range']::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 8px;
  background: #ffffff;
  cursor: pointer;
}
