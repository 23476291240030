.change_img {
    margin-top: 31px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 159px;
    height: 159px;
}
  
  .change_img img {
    display: block;
  }
  
  .change_img *:first-child {
    position: relative;
    z-index: 1;
    cursor: pointer;
  }
  
  .change_img *:last-child {
    position: absolute;
    z-index: 2;
    cursor: pointer;
  }
  