input[type="color"] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  border: 0;
  cursor: pointer;
  height: 35px;
  padding: 0;
  width: 35px;
  border-radius: 15px;
  opacity: 0;
}
.answer{
    margin-top: 14px;
    padding: 20px 21px 22px 24px;
    border-radius: 10px;
    background: #FFFFFF;
  }
  .answerTitle{
    color: #1E1E1E;
    font-family: 'Source Sans 3';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .answerText{
    white-space: pre-line;
    margin-top: 14px;
    color: #000;
    font-family: 'Source Sans 3';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .scale{
    margin-top: 24px;
    display: flex;
    gap: 8px;
}
.scaleMore, .scaleLess{    
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 20px;
    border: 1px solid #C19969;
    background: #FFFFFF;
    cursor: pointer;

    color: #737373;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.circleColor{
    width: 35px;
    height: 35px;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}