
div {
  -ms-overflow-style: none; 
  scrollbar-width: none; 
}

div::-webkit-scrollbar {
  display: none; 
}

@font-face{
  font-family: GauFontPopMagic;
  src: local("GauFontPopMagic");
}
@font-face {
  font-family: Iowan Old Style;
  src: local("Iowan Old Style");
}

body{
  margin: 0;
  padding: 0;
  overflow-y: scroll;
  background: #6f29f1;
}

.main_container {
  padding-top: 72px;
  padding-bottom: 72px;
  padding-right: 14px;
  padding-left: 14px;
  /*full height minus footer*/
  min-height: calc(100vh - 86px - 72px * 2);
}
.title{
  margin: 0 auto;
  padding-right: 36px;
  padding-left: 36px;
  max-width: 918px;
}
.running_line{
  display: flex;
  margin-top: 36px;
  padding-right: 13px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 13px;
  white-space: nowrap;
  overflow-x: hidden;
  box-sizing: border-box;
  color: #FFFFFF;
  font-family: Iowan Old Style;
  font-size: 16.948px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  letter-spacing: 4.068px;
}
.line{
  margin-right: 43px;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgba(0, 0, 0, 0.50);;
  opacity: 1; /* Firefox */
}
.search{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 37px;
}
.search_input{
  max-width: 768px;
  width: 100vw;
  margin-right: 5px;
  border-radius: 10px;
  border: 1px solid #C19969;
  background: #FFFFFF;
  padding: 15px 10px 15px 22px;
  color: rgba(0, 0, 0, 0.50);
  font-family: 'Inter';
  font-size: 16.948px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  box-sizing: border-box;
}
input:focus { 
  outline: none !important;
  /*
  border-color: #C19969;
  box-shadow: 0 0 10px #C19969;
  */
}
.search_button{
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.question_title{
  margin-top: 12px;
  color: #FFFFFF;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration-line: underline;
  cursor: pointer;
}
.sources{
  margin-top: 24px;
  display: flex;
  white-space: nowrap;
  overflow-x: scroll;
}
.source{
  min-width: 320px;
  width: 320px;
  margin-right: 20px;
  padding: 10px 13px 19px 20px;
  border-radius: 10px;
  background: #FFFFFF;
  
}
.source_title{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.source_num{
  color: #63636C;
  font-family: 'Source Sans 3';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.source_fullscreen{
  cursor: pointer;
}
.source_descr{
  margin-top: 20px;
  color: #1E1E1E;
  font-family: 'Source Sans 3';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  text-overflow: ellipsis;
  overflow: hidden; 
  white-space: nowrap;
}
.source_player{
  margin-top: 19px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.play{
  cursor: pointer;
}
.sound{
  cursor: pointer;
}
.treck{
  margin-right: 15px;
  margin-left: 15px;
  width: 200px;
  height: 2px;
  background-color: #1E1E1E;
}
.expanded_view{
  z-index: 1;
  display: none;
  padding-left: 60px;
  padding-right: 60px;
  background: #FFFFFF;
  white-space: normal;
}
.view_block{
  display: block;
  position: relative;
}
.show{
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.exp_view_header{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 144px;
  padding-left: 60px;
  padding-right: 60px;
  background: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.exp_view_title{
  margin-right: 4px;
  color: #1E1E1E;
  font-family: 'Source Sans 3';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.source_basis{
  cursor: pointer;
}
.scroll_content{
  display: flex;
}
.exp_view_source{
  margin-top: 144px;
  margin-bottom: 136px;
  height: 100vh;
  overflow-y: auto;
  color: #1E1E1E;
  font-family: 'Source Sans 3';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 133.333% */
}
.exp_footer{
  position: fixed;
  background: #FFFFFF;
  height: 136px;
  left: 0;
  right: 0;
  bottom: 0;
}
.exp_player{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.exp_player_back {
  cursor: pointer;
}
.exp_player_play{
  cursor: pointer;
  margin-left: 31px;
  margin-right: 31px;
}
.exp_player_forward {
  cursor: pointer;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@media (min-width: 1512px) {
  .title {
      max-width: 918px;
      color: #FFF;
      font-size: 95.195px;
  }
  .running_line {
      margin-top: 50px;
      font-size: 29.659px;
      letter-spacing: 7.118px;
  }
  .line {
      margin-right: 76px;
  }
  .search{
      margin-top: 52px;
  }
  .search_input{
      padding: 17.7px 767.56px 17.7px 25.96px;
      font-family: 'Inter';
      font-size: 19.999px;
  }
}

