.podcast{
    padding: 13px 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10.253px;
    background: var(--Secondary-Primary-White, #FFF);
    box-shadow: 0px 11.534px 25.632px 0px rgba(112, 144, 176, 0.12);
    cursor: pointer;
  }
  .podcast_item{
    display: flex;
    align-items: center;
  }
  .podcast_img{
    display: flex;
    width: 60px;
    height: 60px;
    justify-content: flex-end;
    align-items: center;
    flex-shrink: 0;
    border-radius: 16px;
  }
  .podcast_img img{
    border-radius: 16px;
    border: 1px solid var(--night-separator, #21283F);
  }
  .podcast_name{
    margin-left: 24px;
    color: rgba(0, 0, 0, 0.50);
    font-family: DM Sans;
    font-size: 16.95px;
    font-style: normal;
    font-weight: 500;
    line-height: 12.816px; /* 75.61% */
    letter-spacing: -0.339px;
  }
  .podcast_delete{
    cursor: pointer;
  }