.pagination{
    margin: 0 auto;
    margin-top: 20px;
    width: 82px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.circle{
    background: #FFFFFF;
    border-radius: 100%;
    width: 13px;
    height: 13px;
}
.active{
    background: #FFFFFF55;
    border: 1px solid;
    width: 12px;
    height: 12px;
}