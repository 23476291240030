.footer{
    padding: 15px 38px;
    display: flex;
    height: 86px;
    box-sizing: border-box;
    column-gap: 50px;
    row-gap: 15px;
    flex-wrap: wrap;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    align-content: center;
    justify-content: center;
}
.footerItem{
    color: #FFF;
    font-family: 'Source Sans 3';
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.32px;
    text-decoration-line: underline;
}