.verify{
    box-sizing: border-box;
    height: 306px;
    display: flex;
    padding: 50px 37px 33px 37px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    border-radius: 20px;
}
.greeting{
    color: #FFF;
    font-family: 'Source Sans 3';
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 136.364% */
}
.verifyMail{
    width: calc(100% - 50px);
    max-width: 768px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 22px;
    /* padding: 14px 18px 13px 22px; */
    border-radius: 10px;
    border: 1px solid #C19969;
    background: #FFFFFF;
}
.typeMail{
    width: 100%;
    color: rgba(0, 0, 0, 0.50);
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    outline: none;
    border: none;
    background: transparent;
}
.enterMail{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    cursor: pointer;
}
.seeRules{
    text-align: center;
}
.seePrivacyPolicy{
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.privacyPolicy{
    color: #FFF;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-decoration-line: underline;
}