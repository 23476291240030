body{
    margin: 0;
    padding: 0;
    background: #6f29f1;
  }
.main_container {
    display: flex;
    flex-direction: column;
    padding-top: 24px;
    padding-right: 28px;
    padding-left: 28px;
}
.input_title{
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.505px; /* 136.703% */
  letter-spacing: -0.3px;
  padding-bottom: 20px;
}
.input_text{
  padding: 7px;
  border-radius: 7px;
  border: 1px solid #000;
  opacity: 0.8;
  background: #FFFFFF;
  color: #000;
  font-family: Inter;
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.line{
  margin-top: 20px;
  height: 1px;
  border-top: 0.3px solid #fff;
  border-bottom: 0.3px solid #fff;
  margin-bottom: 10px;
}
.save_btn{
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  box-sizing: border-box;
  border-radius: 11.487px;
  border: 0.82px solid #000;
  background: #FFFFFF;

  color: #000;
  text-align: center;
  font-family: "DM Sans";
  font-size: 17px;
  font-style: normal;
  font-weight: 500;
  line-height: 17.143px; /* 171.429% */
  letter-spacing: -0.2px;
}
