.fade-in {
    opacity: 1;
    transition: opacity 1.5s ease;
}

.fade-out {
    opacity: 0;
    transition: opacity 1.5s ease;
}

.animationContainer {
    text-align: center;
}

a {
    color: white
}