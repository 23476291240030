.errorContainer {
    border-radius: 20px;
    background-color: white;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 36px;
    padding-right: 13px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-left: 13px;
    color: orange;
    font-family: Iowan Old Style;
    font-size: 16.948px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    letter-spacing: 4.068px;
  }