.add_name{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.input_text{
    padding: 7px;
    border-radius: 7px;
    border: 1px solid #000;
    opacity: 0.8;
    background: #FFFFFF;
    color: #000;
    font-family: Inter;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }