.ratingWrapper{
    margin-top: 60px;
}
.rating{
    color: #FFF;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 100% */
    text-align: center;
}
.yourRate{
    margin-top: 16px;
    display: flex;
    gap: 15px;
    justify-content: center;
}
.feedback{
    margin-top: 16px;
    display: flex;
    justify-content: center;
}
.likeBtn {
    cursor: pointer;
}
.feedbackInput{
    max-width: 768px;
    width:  calc(100vw - 53px);
    box-sizing: border-box;
    padding: 24px 27px 6px 26px;
    border-radius: 10px;
    background: #FFFFFF;
    border: none;

    color: #757575;
    font-family: 'Source Sans 3';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.submitFeedback{
    margin-top: 16px;
    display: flex;
    justify-content: center;
    gap: 8px;
}
.feedbackCancel, .feedbackSubmit{
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid #C19969;
    background: #FFFFFF;
    padding: 2px 8px;
    justify-content: center;
    align-items: center;
    color: #000;
    font-family: 'Source Sans 3';
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.thanksForFeedback{
    display: flex;
    flex-direction: column;
    gap: 19px;

    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 100% */
}