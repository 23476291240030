
.circleAnim{
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 150px;
  height: 150px;
}
.circle1{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.50);
  animation: mymove1 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.circle2{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.75);
  animation: mymove2 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.circle3{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  background: #FFFFFF;
  animation-name: mymove3;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}  
@keyframes mymove3 {
  0% {
      width: 0px;
      height: 0px;
  }
  17% {
      width: 50px;
      height: 50px;
  }
  33% {
      width: 50px;
      height: 50px; 
  }
  50% {
      width: 50px;
      height: 50px; 
  }
  67% {
      width: 50px;
      height: 50px;
  }
  83% {
      width: 50px;
      height: 50px;
  }
  100% {
      width: 0px;
      height: 0px;
  }
}
@keyframes mymove2 {
  0% {
      width: 0px;
      height: 0px;
  }
  17% {
  width: 50px;
  height: 50px;
  }
  33% {
  width: 100px;
  height: 100px;
  }
  50%{
      width: 100px;
      height: 100px;
  }
  67% {
      width: 100px;
      height: 100px;
  }
  83% {
      width: 50px;
      height: 50px;
  }
  100% {
      width: 0px;
      height: 0px;
  }
}
@keyframes mymove1 {
  0% {
      width: 0px;
      height: 0px;
  }
  17% {
      width: 50px;
      height: 50px;
  }
  33% {
      width: 100px;
      height: 100px;
  }
  50% {
      width: 150px;
      height: 150px;
  }
  67% {
      width: 100px;
      height: 100px;
  }
  83% {
      width: 50px;
      height: 50px;
  }
  100% {
      width: 0px;
      height: 0px;
  }
}